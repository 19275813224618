import React, {useState} from 'react'
import {Link} from 'react-router-dom';



function Navbar() {
    const [showLinks, setShowLinks] = useState(false);

    return (
        <>
            <nav className="navbar">
                <div className="navbarTitle">
                    <Link className="navbarT" to="/home">Shoppes At Citiside</Link>
                </div>
                <div className="links" id={showLinks ? "hidden" : ""}>
                    <Link className="navbarR" to="/">Home</Link>
                    <Link className="navbarR" to="/tenants">Tenants</Link>
                    <Link className="navbarR" to="/about">About</Link>
                    <Link className="navbarR" to="/contact">Contact us</Link>
                </div>
                <button className="navbarButton" onClick={() => setShowLinks(!showLinks)}>
                    <div className="line1"></div>
                    <div className="line1"></div>
                    <div className="line1"></div>
                </button>
            </nav>
        </>
    )
}

export default Navbar