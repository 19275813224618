import React from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
// import iFrame from 'react-iframe';

function Home() {
    return (
        <div className="home">
            <Navbar/>
            <div className="titleHome">Shoppes At Citiside</div>
            <img src="https://lh3.googleusercontent.com/L2d4TR-Zdpg3D3xK1COcbRf0zvi67OzEvmALcZbyyBVo6OeS_llXIi3BbokutCFNIf36JSVNxqkAXsJyNxsxtM49G8wZA9TeLh7Q_D39W2kdqcClBCt02rR2oz-awPUYg6N6ZqLiKA=w2400" alt="" className="homePhoto"/>
            <div className="homeBody">
                <iFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1629.1431612429149!2d-80.78102934192727!3d35.249131995074286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzXCsDE0JzU2LjkiTiA4MMKwNDYnNDcuOCJX!5e0!3m2!1sen!2sus!4v1617318046315!5m2!!" className="googleMap col-xlg-6 col-lg-6 col-m-9 col-sm-12 col-xsm-12" alt=""></iFrame>
                <div>
                    <div className="title">Shoppes at Citiside located at 4430 The Plaza, Charlotte, NC 28215</div>
                    <div className="contactInfo ">If you would like to know more about our leasing options, Contact us.</div>
                    <div className="contactInfo1">Learn more about us here</div>
                    <button className="aboutButton">
                        <a href="/about" className="bButton">About Page</a>
                    </button>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Home