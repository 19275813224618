import React from 'react'

function Footer() {
    return (
        <>
            <div className="footer">
                {/* <div className="footerM">
                    <div className="title">Shoppes at Citiside located at 4430 The Plaza, Charlotte, NC 28215</div>
                </div> */}
                <div className="footerM">
                    {/* <div className="contactInfo">If you would like to know more about our leasing options, contact us. </div> */}
                    <div className="contactTitle1">Contact us through phone</div>
                    <div className="contactFooter">Ph: 954-868-4342</div>
                    <div className="contactFooter">Ph: 704-775-1321</div>
                    <div className="contactTitle">Contact us through email</div>
                    <div className="contactFooter">E: ShoppesAtCitiside@gmail.com</div>
                    <div className="contactTitle">Contact us through our website</div>
                    <button className="contactButton">
                        <a href="/contact" className="aButton">Contact Page</a>
                    </button>
                </div>
            </div>
            <div className="lowerFooter">
                2021 Shoppes At Citiside
            </div>
        </>
    )
}

export default Footer