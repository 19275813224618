import React, {useRef} from 'react'
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import emailjs from '@emailjs/browser';

function Contact() {

    const form = useRef();

    const sendEmail = (e) => {e.preventDefault();

        emailjs.sendForm('service_0cxsc1c', "template_2prfm5t", form.current, "fbWhoCVQ47W9cfE30")
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };


    return (
        <div>
            <Navbar/>
            <div className="contactPage">
                <div className="requestForm">Contact Form</div>
                <form ref={form} onSubmit={sendEmail}>
                    <div >
                        <input 
                        required
                        minLength="4"
                        maxLength="50"
                        name="fullName"
                        className="input" 
                        placeholder="Enter full name" 
                        type="text"
                        />
                    </div>
                    <div>
                        <input 
                        required
                        minLength="8"
                        maxLength="50"
                        name="email"
                        className="input"
                        placeholder="Enter email" 
                        type="text"
                        />
                    </div>
                    <div>
                        <input 
                        required
                        minLength="10"
                        maxLength="12"
                        name="phoneNumber"
                        className="input" 
                        placeholder="Enter phone number" 
                        type="number"
                        />
                    </div>
                    <div>
                        <textarea 
                        required
                        minLength="20"
                        name="message"
                        className="messageInput" 
                        placeholder="Enter your request" 
                        type="submit"
                        ></textarea>
                    </div>
                    <button type="submit" className="submitButton">Submit</button>
                </form>
                <div className="xInfo">
                    <div className="extraInfo">See below for our email and phone number.</div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Contact
