import React from 'react'
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

function Tenants() {
    return (
        <div>
            <Navbar/>
            <div className="tenantsPage">
                <ul className="topic"> Unit #
                    <li className="tenantsName">1.</li>
                    <li className="tenantsName">2.</li>
                    <li className="tenantsName">3.</li>
                    <li className="tenantsName">4.</li>
                    <li className="tenantsName">5.</li>
                    <li className="tenantsName">6.</li>
                    <li className="tenantsName">7.</li>
                    <li className="tenantsName">8.</li>
                    <li className="tenantsName">9.</li>
                    <li className="tenantsName">10.</li>
                    <li className="tenantsName">11.</li>
                    <li className="tenantsName">12.</li>
                    <li className="tenantsName">13.</li>
                    <li className="tenantsName">14.</li>
                    <li className="tenantsName">15.</li>
                    <li className="tenantsName">16.</li>
                    <li className="tenantsName">17.</li>
                    <li className="tenantsName">18.</li>
                    <li className="tenantsName">19.</li>
                    <li className="tenantsName">20.</li>
                    <li className="tenantsName">21.</li>
                </ul>
                <ul className="topic">Company
                    <li className="tenantsName">Lee Spa Nails</li>
                    <li className="tenantsName">Reggae Center</li>
                    <li className="tenantsName">Nubiance Hair Salon</li>
                    <li className="tenantsName">DTD Services</li>
                    <li className="tenantsName">Jackson Hewitt Tax Prep</li>
                    <li className="tenantsName">US King Nail Supply</li>
                    <li className="tenantsName">Top China</li>
                    <li className="tenantsName">PRM SNEAKER LAB</li>
                    <li className="tenantsName">Louisiana Fried Chicken</li>
                    <li className="tenantsName">Enat Ethiopian Restaurant</li>
                    <li className="tenantsName">Tobacco & Vape Store</li>
                    <li className="tenantsName">Custom Cuts</li>
                    <li className="tenantsName">African Hair Braiding</li>
                    <li className="tenantsName">Asian Market</li>
                    <li className="tenantsName">CityBlock Health Inc</li>
                    <li className="tenantsName">Rainbow</li>
                    <li className="tenantsName">Vidapharmacy</li>
                    <li className="tenantsName">Tima African Restaurant</li>
                    <li className="tenantsName">Cityblock Health Inc.</li>
                    <li className="tenantsName">Credit Union ATM</li>
                    <li className="tenantsName">Better World Recycling</li>
                </ul>
                <ul className="topic">Phone number
                    <li className="tenantsName">704  536  4141</li>
                    <li className="tenantsName">704  377  2782</li>
                    <li className="tenantsName">704  532  2191</li>
                    <li className="tenantsName">704  904  2534</li>
                    <li className="tenantsName">216  650  5842</li>
                    <li className="tenantsName">980  225  2916</li>
                    <li className="tenantsName">704  536  2818</li>
                    <li className="tenantsName">704  526  0328</li>
                    <li className="tenantsName">980  585  2900</li>
                    <li className="tenantsName">980  237  0716</li>
                    <li className="tenantsName">980  219  7824</li>
                    <li className="tenantsName">704  531  0020</li>
                    <li className="tenantsName">980  505  6771</li>
                    <li className="tenantsName">704  595  9998</li>
                    <li className="tenantsName">833  904  2273</li>
                    <li className="tenantsName">704  568  9283</li>
                    <li className="tenantsName">704  837  4410</li>
                    <li className="tenantsName">917  434  2878</li>
                    <li className="tenantsName">833  904  2273</li>
                    <li className="tenantsName">704  376  9133</li>
                    <li className="tenantsName">888  551  3211</li>
                </ul>
                <ul className="topic">Address
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4456+The+Plaza,+Charlotte,+NC+28215/@35.2497647,-80.781517,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb1aeb1f041:0x43c29bd99beb667e!8m2!3d35.2497603!4d-80.7793283">4456 The Plaza Ste. A</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4420+The+Plaza+Ste.+A,+Charlotte,+NC+28215/@35.2496276,-80.7819498,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb10092d959:0x26b83c90ba850bad!8m2!3d35.2496232!4d-80.7797611">4420 The Plaza Ste. B</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4456+The+Plaza+Ste.+C,+Charlotte,+NC+28215/@35.2497647,-80.781517,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb1aeb1f041:0x1e97e7d19919f267!8m2!3d35.2497603!4d-80.7793283">4456 The Plaza Ste. C</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4456+The+Plaza+Ste.+D,+Charlotte,+NC+28215/@35.2497647,-80.781517,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb1aeb1f041:0x7fd909ab8ad2f18a!8m2!3d35.2497603!4d-80.7793283">4456 The Plaza Ste. D</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4456+The+Plaza+Ste.+E,+Charlotte,+NC+28215/@35.2497647,-80.781517,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb1aeb1f041:0x11aaea65baf78c7e!8m2!3d35.2497603!4d-80.7793283">4456 The Plaza Ste. E</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4456+The+Plaza+Ste.+B,+Charlotte,+NC+28215/@35.2497647,-80.781517,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb1aeb1f041:0xe94b07fdc027f01b!8m2!3d35.2497603!4d-80.7793283">4456 The Plaza Ste. B</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4420+The+Plaza+Ste.+D,+Charlotte,+NC+28215/@35.2496276,-80.7819498,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb10092d959:0xabfdf6a174dae21e!8m2!3d35.2496232!4d-80.7797611">4420 The Plaza Ste. D</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4450+The+Plaza+Ste.+F,+Charlotte,+NC+28215/@35.2495547,-80.7809976,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb1b23ab105:0xb84bad7774808e9a!8m2!3d35.2495503!4d-80.7788089">4450 The Plaza Ste. F</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4450+The+Plaza+Ste.+E,+Charlotte,+NC+28215/@35.2495547,-80.7809976,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb1b23ab105:0x1eb138a6a036da52!8m2!3d35.2495503!4d-80.7788089">4450 The Plaza Ste. E</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4450+The+Plaza+D1,+Charlotte,+NC+28215/@35.2495547,-80.7809976,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb1b23ab105:0x5cbb4019d91939cc!8m2!3d35.2495503!4d-80.7788089">4450 The Plaza Ste. D1</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4450+The+Plaza+Ste.+C,+Charlotte,+NC+28215/@35.2495547,-80.7809976,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb1b23ab105:0x81f12b74aa53d1f8!8m2!3d35.2495503!4d-80.7788089">4450 The Plaza Ste. C</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4450+The+Plaza+Ste.+B,+Charlotte,+NC+28215/@35.2495547,-80.7809976,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb1b23ab105:0xcc490e72fd2401a2!8m2!3d35.2495503!4d-80.7788089">4450 The Plaza Ste. B</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4450+The+Plaza+Ste.+A,+Charlotte,+NC+28215/@35.2495547,-80.7809976,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb1b23ab105:0x3fc3d9648a88c5dc!8m2!3d35.2495503!4d-80.7788089">4450 The Plaza Ste. A</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4430+The+Plaza+Ste.+D,+Charlotte,+NC+28215/@35.2495547,-80.7809976,17z/data=!4m5!3m4!1s0x88541fb0f7e5a3d3:0xd439573a8ef23edb!8m2!3d35.2486413!4d-80.7797178">4430 The Plaza Ste. D</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4444+The+Plaza+Ste.+A,+Charlotte,+NC+28215/@35.2486457,-80.7819065,17z/data=!4m5!3m4!1s0x88541fb04c70f603:0x233ebd63fab47e6c!8m2!3d35.249104!4d-80.778625">4444 The Plaza Ste. A</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4438+The+Plaza+Ste.+D,+Charlotte,+NC+28215/@35.2489578,-80.7813313,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb05043b82d:0x427fdeeb08525a7c!8m2!3d35.2489534!4d-80.7791426">4438 The Plaza Ste. D</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4438+The+Plaza+Ste.+D,+Charlotte,+NC+28215/@35.2489578,-80.7813313,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb05043b82d:0x427fdeeb08525a7c!8m2!3d35.2489534!4d-80.7791426">4438 The Plaza Ste. D</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4438+The+Plaza+Ste.+E,+Charlotte,+NC+28215/@35.2489578,-80.7813313,17z/data=!3m1!4b1!4m5!3m4!1s0x88541fb05043b82d:0x6307abc05aa59bfd!8m2!3d35.2489534!4d-80.7791426">4438 The Plaza Ste. E</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/4444+The+Plaza+Ste.+B,+Charlotte,+NC+28215/@35.2489578,-80.7813313,17z/data=!4m5!3m4!1s0x88541fb04c70f603:0xeadbc6149fb98c52!8m2!3d35.249104!4d-80.778625">4444 The Plaza Ste. B</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="/">4430 The Plaza</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="/">4430 The Plaza</a>
                    </li>
                </ul>
                <ul className="topic">Website
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://goo.gl/maps/sDQzot7jsJY2pYMT7">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.facebook.com/reggaecentralstore/">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://goo.gl/maps/dVC2JSGRc9SivMhM8">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://g.page/cecom-international-market?share">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.jacksonhewitt.com/tax-preparation-services-locations/nc/charlotte/14476/">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://goo.gl/maps/JQj9d42hdai8AgQH9">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.prmsneakerlab.com">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.lffccharlotte.com/">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="http://www.enatethiopianrestaurant.com/">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://goo.gl/maps/oqgSsbg77utYsyS27">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://goo.gl/maps/8uaWsMiEeWhBfmwQ7">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/35%C2%B014'58.0%22N+80%C2%B046'44.2%22W/@35.2494421,-80.7795022,192m/data=!3m2!1e3!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d35.2494413!4d-80.7789554">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.asianmarketplz.com">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.google.com/maps/place/35%C2%B014'57.2%22N+80%C2%B046'43.6%22W/@35.2492261,-80.7793252,192m/data=!3m2!1e3!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d35.2492247!4d-80.7787782">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.rainbowshops.com/">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.myvidapharmacy.com/">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="/tenants">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.cityblock.com/north-carolina">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://www.ncsecu.org/">Click me</a>
                    </li>
                    <li className="tenantsName">
                        <a className="websiteLink" href="https://betterworldrecycling.org">Click me</a>
                    </li>
                </ul>
            </div>
            <img className="tenantsPhoto" src="https://lh3.googleusercontent.com/IuKQ03ZwgVcc-kZ8f6OFYlGuWB_aVkX7vrnFqexk5TDnZGIcYWnjFk-QZRsWzL6YpiRREp6_gw3632PXRu68BPkWPXKxwT7a6Gcs0Q4IhXtkB7vv0E90PNJ9u2b8XtBNX6InQMaYow=w2400" alt=""/>
            <Footer/>
        </div>
    )
}

export default Tenants