import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer';

function About() {
    return (
        <div className="aboutPage">
            <Navbar/>
                <div className="aboutBody">
                    <center className="about1">
                        Shoppes at Citiside is a plaza located in Charrlotte, North Carolina.
                        The plaza ocupies 19 different tentants, and has a totel of 75,572 sqft.
                    </center>
                    <br/>
                    {/* <iFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1629.1431612429149!2d-80.78102934192727!3d35.249131995074286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzXCsDE0JzU2LjkiTiA4MMKwNDYnNDcuOCJX!5e0!3m2!1sen!2sus!4v1617318046315!5m2!!" className="googleMap"></iFrame> */}
                    <br/>
                    <img className="aboutPhoto" src="https://lh3.googleusercontent.com/sqZtAwgTjRWKLK33atztkARhkuMQcLCwMIqDpW72gr2dJdICQa07NgXZfQaNiEKbs96gbi1xBIhuzTtPLG5VrURXWcro3tYe_yo3g0d63FcO5nfez7DXO7TKYQC-ye4sbL_QcZdzpg=w2400" alt=""/>
                    <br/>
                    <center className="about2">
                        At Shoppes At Citiside, you'll find everything you need from unique retailers to sunning boutiques.
                        We're much more than just another shopping center.
                        We are all about enhancing your live, from fashion to beautity to entertainment to exotic food.
                    </center>
                    <br/>
                    <img className="aboutPhoto" src="https://lh3.googleusercontent.com/7U9p8TLrMugJiAe98IhjWuO6JSu1NtlvQ2vVoNfctZDpecNtgNL8mpzacvOLb-Kv4rL1nQzMVcgGmTQVr2LeD2DzVKX6hsNk7SlpfjNCFIZmVE4j5az1rbPYIBqmNPH2xFN7MeAeLA=w2400" alt=""/>
                    <br/>
                    <center className="about2">
                        From the stores you adore for the latest in fashion and style, to the personal services you need in one convenient location.
                        To the resturents you crasve, trying to give our guests the best experience.
                    </center>
                    <br/>
                </div>
            <Footer/>
        </div>
    )
}

export default About
