import React from 'react'
import { BrowserRouter, Route, Switch} from "react-router-dom";
import Home from './Pages/Home';
import About from './Pages/About';
import Tenants from './Pages/Tenants';
import Contact from './Pages/Contact';

export default function Layout() {
    return (
        <div>
            <BrowserRouter>
                <div>  
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/home" component={Home}/>
                        <Route exact path="/about" component={About}/>
                        <Route exact path="/tenants" component={Tenants}/>
                        <Route exact path="/contact" component={Contact}/>
                        {/* <Route exact path="/" component={}/>
                        <Route exact path="/" component={}/>
                        <Route exact path="/" component={}/> */}
                        <Route render={() => <h1 className="notFound">***Page does not exist***</h1>} />
                    </Switch>
                </div>
            </BrowserRouter>
        </div>
    )
}
